<template>
  <div class="relative max-sm:pt-[23px]">
    <div ref="sl" class="keen-slider sl-slider max-sm:!overflow-visible">
      <div
        v-for="item in this.dataArr"
        :key="item.id"
        class="keen-slider__slide mb-12 flex flex-col"
      >
        <span
          v-if="!item.available"
          class="absolute left-[50%] top-0 z-10 w-full translate-x-[-50%] bg-ss3_light_blue px-10 py-5 text-center text-white"
          >In-Store Only
        </span>

        <a
          :href="item.url"
          class="group group relative mx-auto mb-15 block transition-all duration-500 hover:scale-105"
        >
          <img
            :src="item.featured_image"
            :alt="item.title"
            class="mx-auto w-[256px] object-cover"
          />
          <img
            v-if="item?.images.length > 0"
            :src="item?.images[1]?.src"
            :alt="item.title"
            class="absolute left-0 top-0 h-full w-full object-cover opacity-0 group-hover:opacity-100"
          />
        </a>
        <junip
          :product-id="item?.id"
          :store-key="junipkey"
          :link="item?.url"
        ></junip>
        <a
          :href="item.url"
          class="my-[15px] block font-bionM hover:underline max-sm:mt-10 max-sm:leading-5 sm:text-[18px] sm:leading-6"
        >
          {{ item?.title }}
        </a>
        <span class="mb-20 block flex-auto font-bionM">
          {{ item?.price }}
        </span>
        <form v-if="item.available" id="product-form">
          <AddToCartMisc v-slot="{loading, disabled, addToCart}">
            <button
              type="submit"
              @click.prevent="addToCart(item.variants[0].id)"
              class="btn-block max-md:h-30"
            >
              <span v-if="loading">Loading</span>
              <span v-else>
                <span class="hidden xs:block">Add To Cart</span>
                <span class="block xs:hidden">
                  <svg
                    class="svg-icon"
                    style="
                      width: 1em;
                      height: 1em;
                      vertical-align: middle;
                      fill: currentColor;
                      overflow: hidden;
                    "
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M849.066667 349.866667h-115.2c4.266667-25.6 0-51.2-8.533334-81.066667-34.133333-119.466667-157.866667-187.733333-277.333333-157.866667-55.466667 21.333333-102.4 59.733333-132.266667 110.933334-21.333333 38.4-29.866667 85.333333-29.866666 128H174.933333c-17.066667 0-29.866667 12.8-29.866666 34.133333v337.066667c0 115.2 93.866667 213.333333 213.333333 213.333333h200.533333c17.066667 0 34.133333-12.8 34.133334-34.133333s-12.8-34.133333-34.133334-34.133334H354.133333c-81.066667 0-149.333333-68.266667-149.333333-149.333333V413.866667H810.666667v174.933333c0 17.066667 12.8 34.133333 34.133333 34.133333s34.133333-12.8 34.133333-34.133333V384c0-21.333333-12.8-34.133333-29.866666-34.133333z m-179.2 0H354.133333c-4.266667-34.133333 0-68.266667 17.066667-98.133334C392.533333 213.333333 426.666667 187.733333 469.333333 174.933333c85.333333-25.6 174.933333 25.6 196.266667 110.933334 4.266667 21.333333 8.533333 42.666667 4.266667 64z"
                      fill="#080405"
                    />
                    <path
                      d="M849.066667 704h-132.266667v-132.266667c0-17.066667-12.8-29.866667-34.133333-29.866666-17.066667 0-29.866667 12.8-29.866667 34.133333v132.266667h-132.266667c-17.066667 0-29.866667 12.8-29.866666 34.133333 0 17.066667 12.8 29.866667 34.133333 29.866667h132.266667v132.266666c0 17.066667 12.8 29.866667 34.133333 29.866667 17.066667 0 29.866667-12.8 29.866667-34.133333V768H853.333333c17.066667 0 29.866667-12.8 29.866667-34.133333-4.266667-17.066667-17.066667-29.866667-34.133333-29.866667z"
                      fill="#1A1311"
                    />
                  </svg>
                </span>
              </span>
            </button>
          </AddToCartMisc>
        </form>
      </div>
    </div>
    <!-- Slider Controllers -->
    <div class="mt-30 flex items-center justify-between gap-5 sm:hidden">
      <button
        @click="
          () => {
            slider.prev();
          }
        "
      >
        <svg
          width="16"
          height="27"
          viewBox="0 0 16 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_60_93)">
            <path
              d="M13.5193 25.4783L2.48083 13.5L13.5193 1.52168"
              stroke="#3F3F3F"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_60_93">
              <rect
                width="16"
                height="27"
                fill="white"
                transform="translate(16 27) rotate(-180)"
              />
            </clipPath>
          </defs>
        </svg>
      </button>

      <!-- dots -->
      <div class="flex w-full justify-center">
        <div
          v-if="slider"
          class="dots-gift flex flex-wrap justify-center gap-10"
        >
          <button
            type="button"
            v-for="(_slide, idx) in dotHelper.slice(0, -1)"
            @click="
              () => {
                slider.moveToIdx(idx);
              }
            "
            :class="{dot: true, active: current === idx}"
            :key="idx"
          ></button>
        </div>
      </div>

      <button
        @click="
          () => {
            slider.next();
          }
        "
      >
        <svg
          width="16"
          height="27"
          viewBox="0 0 16 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_60_91)">
            <path
              d="M2.48071 1.52173L13.5192 13.5L2.48071 25.4783"
              stroke="#3F3F3F"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_60_91">
              <rect width="16" height="27" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import {computed, onMounted, ref} from "vue";
import {useKeenSlider} from "keen-slider/vue.es";
import "keen-slider/keen-slider.min.css";
import AddToCart from "../AddToCart";
import junip from "../Junip/index.vue";

export default {
  name: "collection-products",
  props: {
    data: {
      type: String,
      required: true,
    },
  },
  components: {AddToCart, junip},
  data() {
    return {
      dataArr: JSON.parse(this.data),
      junipkey: window.__theme?.junip?.storekey || "CP9UUhju5nUNfaMofet11Bwo",
    };
  },
  setup() {
    const current = ref(0);
    const [sl, slider] = useKeenSlider({
      loop: false,
      breakpoints: {
        "(max-width: 1025px)": {
          slides: {
            perView: 4,
            spacing: 20,
          },
        },
        "(max-width: 767px)": {
          slides: {
            perView: 3,
            spacing: 20,
          },
        },
        "(max-width: 640px)": {
          slides: {
            perView: 1.5,
            spacing: 20,
          },
        },
      },
      slides: {
        perView: 4,
        spacing: 18,
      },
      slideChanged: s => {
        current.value = s.track.details.rel;
      },
    });
    const dotHelper = computed(() =>
      slider.value
        ? [...Array(slider.value.track.details.slides.length).keys()]
        : [],
    );

    return {sl, current, dotHelper, slider};
  },
  mounted() {
    console.log(this.dataArr);
  },
};
</script>
<style>
.dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid #3f3f3f;
}

.dot:after {
  display: none !important;
}

.dot.active {
  background-color: #90c540;
  border-color: transparent;
}
</style>
