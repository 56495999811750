<template>
  <div
    class="text-blackDark flex w-full flex-col"
    :class="{
      '-right-full': !visible && fixed,
      'right-0': visible && fixed,
      'shadow-header fixed bottom-0 top-0 z-[501] overflow-y-auto bg-white xl:w-[85%]':
        fixed,
    }"
  >
    <!-- Main Wrap -->
    <div class="p-20">
      <div class="mb-[25px] flex flex-row justify-between">
        <!-- Left Col -->
        <div class="font-normal">
          <!-- Cart Title -->
          <div class="mb-3 text-[28px] uppercase leading-9 tracking-[0.75]">
            <h3>Your basket</h3>
          </div>
          <!-- Items Counter -->
          <div class="text-sm uppercase leading-[18px] tracking-[1px]">
            <p v-if="items.length !== 0">{{ cartData.item_count }} Items</p>
          </div>
        </div>
        <!-- Right Col -->
        <div v-if="fixed">
          <button
            @click="toggle(), this.updNoteMess()"
            title="Continue Shopping"
            class="flex flex-col-reverse items-center text-ss3_green"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
            >
              <line
                x1="0.707107"
                y1="1.29289"
                x2="19.7071"
                y2="20.2929"
                stroke="currentColor"
                stroke-width="2"
              />
              <path
                d="M20.2928 1.29285L1.29285 20.2928"
                stroke="currentColor"
                stroke-width="2"
              />
            </svg>
          </button>
        </div>
      </div>

      <!--  -->
      <div
        v-if="items.length !== 0"
        class="md:gap-[60px] lg:grid lg:grid-cols-12"
      >
        <div class="lg:col-span-8">
          <ul class="mb-15">
            <!-- Static Item Example -->
            <li class="border-b-thin_grey pb-3 hidden border-b">
              <div class="bg-blue_thin px-[10px] py-[10px]">
                <div
                  class="border-b-thin_grey font-brother text-navy mb-3 pb-3 flex flex-row items-center justify-between border-b text-sm font-normal uppercase"
                >
                  <div class="gap-2 flex items-center">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.7331 9.00012C16.7331 13.5704 13.3888 16.6173 9.26333 16.6173C5.91058 16.6173 3.2055 14.8387 2.2605 12.047"
                        stroke="#00C761"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M1.5 8.99996C1.5 4.42967 4.84431 1.38281 8.96976 1.38281C12.3225 1.38281 15.0276 3.16133 15.9726 5.9531"
                        stroke="#00C761"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5.30857 12.8085L2.26172 12.0468L1.5 15.0936"
                        stroke="#00C761"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12.9259 5.19127L15.9728 5.95299L16.7345 2.90613"
                        stroke="#00C761"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    Subscriptions
                  </div>
                  <div>
                    <!-- Percents Wrap -->
                    <div class="leading-[18px] tracking-[1px]">
                      <p class="border-green border px-[8px] py-[3px]">
                        25% Off
                      </p>
                    </div>
                  </div>
                </div>
                <!--  -->
                <div class="flex flex-row items-center gap-5">
                  <div>
                    <a :href="cart.product?.url">
                      <img :src="cart.product" alt="" />
                    </a>
                  </div>
                  <!--  -->
                  <div class="flex grow flex-row items-center gap-5">
                    <div class="basis-2/3">
                      <div
                        class="flex flex-row items-center justify-between max-md:flex-col"
                      >
                        <div class="font-sofia text-navy font-normal">
                          <!-- Brand Title -->
                          <div
                            class="text-xs leading-4 opacity-60 max-md:hidden"
                          >
                            <a href="">BOCCES BAKERY</a>
                          </div>
                          <!-- Product Title -->
                          <div class="text-base leading-[22.5px]">
                            <a>Pumpk'n Harvest Biscuits</a>
                          </div>
                        </div>
                        <!-- quantity -->
                        <div class="max-md:mr-auto">
                          <div
                            class="border-navy bg-blue_thin font-brother text-navy flex w-[75px] flex-row items-center justify-center border text-center text-sm font-normal leading-5"
                          >
                            <button class="p-1">
                              <svg
                                width="10"
                                height="12"
                                viewBox="0 0 10 2"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M0.802612 1.17578H9.80261"
                                  stroke="#1C4270"
                                />
                              </svg>
                            </button>
                            <div class="p-1 w-[30px]">1</div>
                            <button class="p-1">
                              <svg
                                width="10"
                                height="12"
                                viewBox="0 0 10 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M0.802612 6.17578H9.50391"
                                  stroke="#1C4270"
                                />
                                <path
                                  d="M5.15332 0.406494L5.15332 11.945"
                                  stroke="#1C4270"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="basis-1/3">
                      <div
                        class="flex flex-row justify-end gap-5 max-md:flex-col max-md:text-right"
                      >
                        <div
                          class="font-sofia text-navy self-center text-center text-sm font-normal leading-5 line-through max-md:hidden"
                        >
                          <p>$16.00</p>
                        </div>
                        <div
                          class="font-brother text-[17px] font-normal leading-[23.43px] tracking-[1.5px] text-ss3_purple"
                        >
                          <h6>$14.00</h6>
                        </div>
                        <div>
                          <button>
                            <span
                              class="font-sofia ml-auto text-sm font-normal leading-5 text-[#757575] underline md:hidden"
                              >Remove</span
                            >
                            test
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="21"
                              height="21"
                              viewBox="0 0 21 21"
                              fill="none"
                              class="text-ss3_green"
                            >
                              <line
                                x1="0.707107"
                                y1="1.29289"
                                x2="19.7071"
                                y2="20.2929"
                                stroke="currentColor"
                                stroke-width="2"
                              />
                              <path
                                d="M20.2928 1.29285L1.29285 20.2928"
                                stroke="currentColor"
                                stroke-width="2"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <!-- Looping through the items -->
            <li
              v-for="(item, itemK) in items"
              :key="itemK"
              class="border-b-thin_grey border-b py-10"
              :class="'selling_plan_allocation' in item ? '' : ''"
            >
              <div
                v-if="'selling_plan_allocation' in item"
                class="bg-blue_thin px-[10px] py-[10px]"
              >
                <div
                  class="border-b-thin_grey font-brother text-navy mb-3 pb-3 flex flex-row items-center justify-between border-b text-sm font-normal uppercase"
                >
                  <div class="gap-2 flex items-center">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.7331 9.00012C16.7331 13.5704 13.3888 16.6173 9.26333 16.6173C5.91058 16.6173 3.2055 14.8387 2.2605 12.047"
                        stroke="#00C761"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M1.5 8.99996C1.5 4.42967 4.84431 1.38281 8.96976 1.38281C12.3225 1.38281 15.0276 3.16133 15.9726 5.9531"
                        stroke="#00C761"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5.30857 12.8085L2.26172 12.0468L1.5 15.0936"
                        stroke="#00C761"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12.9259 5.19127L15.9728 5.95299L16.7345 2.90613"
                        stroke="#00C761"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Subscription
                  </div>
                  <div>
                    <!-- Percents Wrap -->
                    <div class="leading-[18px] tracking-[1px]">
                      <p
                        v-if="
                          item.selling_plan_allocation.selling_plan
                            .price_adjustments[0].value_type == 'percentage'
                        "
                        class="border-green border px-[8px] py-[3px]"
                      >
                        {{
                          item.selling_plan_allocation.selling_plan
                            .price_adjustments[0].value
                        }}% Off
                      </p>
                    </div>
                  </div>
                </div>
                <!--  -->
                <div class="flex flex-row items-center gap-5">
                  <div class="w-[100px] flex-shrink-0">
                    <a :href="item.url">
                      <img
                        class="w-[100px]"
                        :src="item.image"
                        :alt="item.featured_image.alt"
                        loading="lazy"
                      />
                    </a>
                  </div>
                  <!--  -->
                  <div class="flex grow flex-row items-center gap-5">
                    <div class="basis-2/3">
                      <div
                        class="flex flex-row items-center justify-between max-md:flex-col"
                      >
                        <div class="font-sofia text-navy font-normal">
                          <!-- Brand Title -->
                          <div
                            class="text-xs leading-4 opacity-60 max-md:hidden"
                          >
                            <a v-if="item.id == this.cart.gwp_product">{{
                              this.cart.lineItem
                            }}</a>
                            <a v-else
                              >{{ item.vendor }} -
                              {{
                                item.selling_plan_allocation.selling_plan.name
                              }}</a
                            >
                          </div>
                          <!-- Product Title -->
                          <div class="text-base leading-[22.5px]">
                            <a :href="item.url">{{ decodeHTML(item.title) }}</a>
                          </div>
                        </div>
                        <!-- quantity -->
                        <div
                          class="max-md:mr-auto"
                          :class="
                            item.id == this.cart.gwp_product ? 'hidden' : ''
                          "
                        >
                          <div
                            class="border-navy bg-blue_thin font-brother text-navy flex w-[75px] flex-row items-center justify-center border text-center text-sm font-normal leading-5"
                          >
                            <button
                              @click="
                                this.updItem({item: item, type: 'decrement'})
                              "
                              class="p-1"
                            >
                              <svg
                                width="10"
                                height="12"
                                viewBox="0 0 10 2"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M0.802612 1.17578H9.80261"
                                  stroke="#1C4270"
                                />
                              </svg>
                            </button>
                            <input
                              type="text"
                              style="
                                background: transparent;
                                outline: none;
                                width: 35px;
                              "
                              class="mx-auto border-none p-5 text-center"
                              :value="item.quantity"
                              @keypress="isNumber($event)"
                              @blur="
                                handleInputChange($event.target.value, item)
                              "
                            />
                            <button
                              @click="
                                this.updItem({item: item, type: 'increment'})
                              "
                              class="p-1"
                            >
                              <svg
                                width="10"
                                height="12"
                                viewBox="0 0 10 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M0.802612 6.17578H9.50391"
                                  stroke="#1C4270"
                                />
                                <path
                                  d="M5.15332 0.406494L5.15332 11.945"
                                  stroke="#1C4270"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="basis-1/3">
                      <div
                        class="flex flex-row justify-end gap-5 max-md:flex-col max-md:text-right"
                      >
                        <div
                          class="font-sofia text-navy self-center text-center text-sm font-normal leading-5 line-through max-md:hidden"
                        >
                          <p>
                            {{ formatMoney(item.compare_at_price) }}
                          </p>
                        </div>
                        <div
                          class="font-brother text-[17px] font-normal leading-[23.43px] tracking-[1.5px] text-[#FF4954]"
                        >
                          <h6>{{ formatMoney(item.price) }}</h6>
                        </div>
                        <div>
                          <button @click="this.remItem(item)">
                            <span
                              class="font-sofia ml-auto text-sm font-normal leading-5 text-[#757575] underline md:hidden"
                              >Remove</span
                            >
                            <svg
                              class="max-md:hidden"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M17.8431 6.15698L6.52943 17.4707"
                                stroke="#00C761"
                                stroke-width="2"
                                stroke-linecap="round"
                              />
                              <path
                                d="M17.8431 17.4707L6.52943 6.15699"
                                stroke="#00C761"
                                stroke-width="2"
                                stroke-linecap="round"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--  -->
              <div v-else class="flex flex-row items-center gap-5">
                <div class="w-[100px] flex-shrink-0">
                  <a :href="item.url">
                    <img
                      class="w-[100px]"
                      :src="item.image"
                      :alt="item.featured_image.alt"
                      loading="lazy"
                    />
                  </a>
                </div>
                <!--  -->
                <div class="flex grow flex-row items-center gap-5">
                  <div class="basis-2/3">
                    <div
                      class="gap-2 flex flex-row items-center justify-between max-md:flex-col"
                    >
                      <div class="font-sofia text-navy font-normal">
                        <!-- Brand Title -->
                        <div class="text-xs leading-4 opacity-60 max-md:hidden">
                          <a
                            v-if="
                              item.id == this.cart.gwp_product ||
                              item.id == this.cart.gwp_product_1 ||
                              item.id == this.cart.gwp_product_2
                            "
                            >{{ this.cart.lineItem }}</a
                          >
                          <a v-else>{{ item.vendor }}</a>
                        </div>
                        <!-- Product Title -->
                        <div class="text-base leading-[22.5px]">
                          <a :href="item.url">{{ decodeHTML(item.title) }}</a>
                        </div>
                      </div>
                      <!-- quantity -->
                      <div
                        class="max-md:mr-auto"
                        :class="{
                          hidden:
                            item.id == cart.gwp_product ||
                            item.id == cart.gwp_product_1 ||
                            item.id == cart.gwp_product_2,
                        }"
                      >
                        <div
                          class="border-navy bg-blue_thin font-brother text-navy flex w-[75px] flex-row items-center justify-center border text-center text-sm font-normal leading-5"
                        >
                          <button
                            @click="
                              this.updItem({item: item, type: 'decrement'})
                            "
                            class="p-5"
                          >
                            <svg
                              width="10"
                              height="12"
                              viewBox="0 0 10 2"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0.802612 1.17578H9.80261"
                                stroke="#1C4270"
                              />
                            </svg>
                          </button>
                          <input
                            type="text"
                            style="
                              background: transparent;
                              outline: none;
                              width: 35px;
                            "
                            class="mx-auto border-none p-5 text-center"
                            :value="item.quantity"
                            @keypress="isNumber($event)"
                            @blur="handleInputChange($event.target.value, item)"
                          />
                          <button
                            @click="
                              this.updItem({item: item, type: 'increment'})
                            "
                            class="p-5"
                          >
                            <svg
                              width="10"
                              height="12"
                              viewBox="0 0 10 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0.802612 6.17578H9.50391"
                                stroke="#1C4270"
                              />
                              <path
                                d="M5.15332 0.406494L5.15332 11.945"
                                stroke="#1C4270"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="basis-1/3">
                    <div
                      class="flex flex-row justify-end gap-5 max-md:flex-col max-md:text-right"
                    >
                      <div
                        v-if="item.price == item.final_price"
                        class="font-brother text-navy text-[17px] font-normal leading-[23.43px] tracking-[1.5px]"
                      >
                        <h6
                          :class="{
                            'line-through':
                              item.id == cart.gwp_product ||
                              item.id == cart.gwp_product_1 ||
                              item.id == cart.gwp_product_2,
                          }"
                        >
                          {{ formatMoney(item.price) }}
                        </h6>
                        <p
                          v-if="
                            item.id == this.cart.gwp_product ||
                            item.id == cart.gwp_product_1 ||
                            item.id == cart.gwp_product_2
                          "
                          class="text-xs"
                        >
                          {{ this.cart.disclamer }}
                        </p>
                      </div>
                      <div
                        v-if="item.price > item.final_price"
                        class="font-sofia text-navy self-center text-center text-sm font-normal leading-5 line-through max-md:hidden"
                      >
                        <p>{{ formatMoney(item.price) }}</p>
                      </div>
                      <div
                        v-if="item.price > item.final_price"
                        class="font-brother text-[17px] font-normal leading-[23.43px] tracking-[1.5px] text-[#FF4954]"
                      >
                        <h6
                          :class="{
                            'line-through':
                              item.id == cart.gwp_product ||
                              item.id == cart.gwp_product_1 ||
                              item.id == cart.gwp_product_2,
                          }"
                        >
                          {{ formatMoney(item.final_price) }}
                        </h6>
                      </div>
                      <div>
                        <button @click="this.remItem(item)">
                          <span
                            class="font-sofia ml-auto text-sm font-normal leading-5 text-[#757575] underline md:hidden"
                            >Remove</span
                          >
                          <svg
                            class="text-ss3_green max-md:hidden"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M17.8431 6.15698L6.52943 17.4707"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                            />
                            <path
                              d="M17.8431 17.4707L6.52943 6.15699"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <template v-if="this.cart.enable_note">
            <p class="">{{ this.cart.note_lable }}</p>
            <textarea
              class="mt-10 w-full border bg-transparent p-10 outline-1 !outline-ss3_green"
              name="note"
              ref="myTextarea"
              @blur="noteMessages(this.noteMessage)"
              v-model="this.noteMessage"
              :placeholder="this.cart.note_placeholder"
              maxlength="150"
              @input="filterEmojis"
            ></textarea>
            <p class="text-new_grey text-right">
              {{ 150 - this.noteMessage.length }}
            </p>
          </template>
        </div>
        <div class="max-md:grid md:col-span-4">
          <!-- Subtotal section -->
          <div class="mb-[30px] bg-[#F7F9F9] px-10 py-[30px]">
            <!-- Subtotal -->
            <div
              class="font-brother text-navy mb-[15px] flex flex-row items-end justify-between uppercase"
            >
              <div
                class="text-[21px] font-bold leading-[26.43px] tracking-[0.75px]"
              >
                Subtotal
              </div>
              <div
                class="text-[17px] font-bold leading-[23.43px] tracking-[1.5px]"
              >
                {{ formatMoney(cartData.total_price) }}
              </div>
            </div>
            <!-- PB Wrap -->
            <div
              v-show="this.freeShipp"
              class="border-b-thin_grey mb-[15px] border-b pb-[15px]"
            >
              <div class="mb-5 text-center text-sm font-normal leading-5">
                <p v-html="freeShipping"></p>
              </div>
              <!-- Progressbar -->
              <div class="px-10">
                <div class="block h-[10px] w-full border border-ss3_green">
                  <div
                    class="block h-[9px] bg-ss3_green"
                    :style="styleGwp"
                  ></div>
                </div>
              </div>
            </div>
            <!-- Saving Wrap -->
            <div
              class="font-sofia mb-[15px] flex flex-row items-center justify-between text-base font-normal"
            >
              <div class="text-navy leading-[22px]">Savings</div>
              <div class="leading-[22px] text-ss3_purple">
                {{ formatMoney(savings) }}
              </div>
            </div>
            <!-- Checkout BTN Wrap -->
            <div>
              <a href="/checkout" class="btn-block mx-auto"> Checkout </a>
            </div>
          </div>
          <!-- Gifts -->
          <!-- Gifts -->
          <div
            v-if="cart?.gwp_enabled"
            class="font-sofia text-navy mb-30 w-full"
          >
            <div v-if="!eligibleGWP" class="mb-[15px]">
              <div class="mb-5 text-center text-sm font-normal leading-5">
                <p v-html="gwp"></p>
              </div>
              <!-- Progressbar -->
              <div class="px-10">
                <div class="block h-[10px] w-full border border-ss3_green">
                  <div
                    class="block h-[9px] bg-ss3_green"
                    :style="styleGwp"
                  ></div>
                </div>
              </div>
              <!-- Notice -->
              <div
                v-if="this.totalWS != this.cartData.items_subtotal_price"
                class="font-sofia text-navy my-5 text-center text-sm font-normal leading-5"
              >
                <p>
                  <span class="text-[#FF4954]">*</span>subscription products do
                  not count towards gift with purchase
                </p>
              </div>
            </div>
            <!--  -->
            <div
              v-if="eligibleGWP"
              class="flex flex-row items-center justify-between gap-5"
            >
              <div class="text-sm font-normal leading-5">
                <p>
                  {{ this.gwp }}
                </p>
              </div>
              <div class="hidden">
                <button
                  @click="this.gwpExp = !this.gwpExp"
                  class="gap-2 flex flex-row"
                >
                  <span
                    v-if="this.gwpExp"
                    class="border-b-green w-max border-b text-sm font-bold leading-5"
                    >Close</span
                  >
                  <span
                    v-else
                    class="border-b-green w-max border-b text-sm font-bold leading-5"
                    >Add Gifts</span
                  >
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    :class="this.gwpExp ? 'rotate-45 transform' : ''"
                  >
                    <path
                      d="M8.5 0.764404V16.7644"
                      stroke="#00C761"
                      stroke-width="2"
                    />
                    <path
                      d="M16.5 8.7644L0.5 8.7644"
                      stroke="#00C761"
                      stroke-width="2"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <!-- List Items -->
            <ul
              v-if="this.gwpExp && this.eligibleGWP"
              class="w-full pr-[10px] pt-[15px]"
            >
              <slot
                name="gwplist"
                :addGWPtoCart="this.addGWPtoCart"
                :cartHasGWP="this.cartHasGWP"
                :cartHasGWP1="this.cartHasGWP1"
                :cartHasGWP2="this.cartHasGWP2"
              ></slot>
            </ul>
          </div>
          <!-- Cross Sale -->
          <div
            v-if="cart.upsale_enabled"
            class="text-navy mb-[30px] max-md:order-last"
          >
            <!-- Title -->
            <div
              class="font-sofia mb-5 text-[20px] font-bold capitalize leading-[26px] tracking-[0.25px] md:pl-[15px]"
            >
              <h4>{{ cart.upsale_headline }}</h4>
            </div>
            <!-- List Cross Sale Items -->
            <ul
              class="border-thin_grey w-full border pl-[15px] pr-[29px] pt-[15px]"
            >
              <slot name="upsale" :addUpsale="this.addUpsale"></slot>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- empty cart Img -->
    <div
      v-if="items.length === 0"
      class="tracking-2p text-blue py-50 text-center uppercase"
    >
      No items in cart
    </div>
  </div>
</template>
<script>
import {mapActions, mapState} from "vuex";
import {formatMoney} from "@shopify/theme-currency";

import Slideout from "@hyjiacan/vue-slideout";
import "@hyjiacan/vue-slideout/dist/slideout.css";

import CartCount from "../CartCount";

import axios from "axios";

export default {
  name: "StoreCart",
  components: {Slideout, CartCount},
  props: {
    cart: {
      type: Object,
      required: true,
    },
    fixed: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      size: "80%",
      freeShipp: false,
      gwpExp: false,
      cartHasGWP: false,
      cartHasGWP1: false,
      cartHasGWP2: false,
      savings: 0,
      noteMessage: "",
      noteUpdateRequested: false,
      totalWS: 0, //total amount without subscription products
    };
  },
  computed: {
    ...mapState("cart", ["cartData", "visible", "loading"]),
    items() {
      return this.cartData ? this.cartData.items : [];
    },
    emptyCart() {
      var status = this.items.length == 0 ? true : false;
      return status;
    },
    freeShipping() {
      if (!this.emptyCart && this.cart.freeShipp > 0) {
        // this.freeShipp = true; // display block in the cart
        this.showFreeShippingBlock();

        if (this.cart.freeShipp - this.cartData.total_price / 100 > 0) {
          return (
            'You are <span class="font-bold">$' +
            (this.cart.freeShipp - this.cartData.total_price / 100).toFixed(2) +
            "</span> away from free shipping!"
          );
        }
        return "Congrats! You get free shipping.";
      }
      return undefined;
    },
    styleFreeShipping() {
      if (this.cartData.total_price / 100 > this.cart.freeShipp) {
        return "width: 100%;";
      } else {
        return `width: ${
          (this.cartData.total_price / 100 / this.cart.freeShipp) * 100
        }%;`;
      }
    },
    eligibleGWP() {
      if (
        parseFloat(formatMoney(this.totalWS, "{{amount}}")) >=
        parseFloat(this.cart.threshold)
      ) {
        return true;
      } else {
        return false;
      }
    },
    gwp() {
      if (
        parseFloat(this.cart.threshold) - parseFloat(this.totalWS) / 100 >
        0
      ) {
        var spendMore =
          parseFloat(this.cart.threshold) - parseFloat(this.totalWS) / 100;
        return this.cart.approaching.replace("[XX]", spendMore.toFixed(2));
      } else if (this.cartHasGWP) {
        return this.cart.gwpActivated;
      } else {
        return this.cart.congrats;
      }
    },
    styleGwp() {
      if (this.totalWS / 100 > this.cart.threshold) {
        return "width: 100%;";
      } else {
        return `width: ${(this.totalWS / 100 / this.cart.threshold) * 100}%;`;
      }
    },
  },
  beforeMount() {
    this.$store.dispatch("cart/initCart");
  },
  methods: {
    ...mapActions("cart", {
      init: "initCart",
      toggle: "toggle",
      addItem: "addItem",
      remItem: "removeItem",
      updItem: "updateItem",
      hideCart: "hide",
      updNote: "updateNote",

      hide: "hide",
      show: "show",
    }),
    formatMoney(value) {
      return formatMoney(value, "${{amount}}");
    },
    async getProduct(handle) {
      const url = `/products/${handle}.js`;
      return await axios.get(url).then(({data}) => data);
    },
    async totalSavings() {
      var saveAmount = 0;
      for (let index = 0; index < this.cartData.items.length; index++) {
        let element = this.cartData.items[index];
        let product = await this.getProduct(element.handle);
        let productVar = product.variants.find(obj => obj.id === element.id);
        if (productVar.compare_at_price > productVar.price) {
          let save =
            (productVar.compare_at_price - productVar.price) * element.quantity;
          saveAmount += save;
        }
      }
      this.savings = saveAmount;
      return saveAmount;
    },
    cartSize() {
      var status = this.items.length == 0 ? true : false;
      if (status) {
        if (screen.width < 768) {
          this.size = "100%";
        } else {
          this.size = "390px";
        }
      } else {
        if (screen.width < 1420) {
          this.size = "100%";
        } else if (screen.width < 1500) {
          this.size = "90%";
        } else if (screen.width < 1700) {
          this.size = "85%";
        } else {
          this.size = "80%";
        }
      }
      // Create event listner on resize
      window.addEventListener("resize", () => {
        if (screen.width < 1420) {
          this.size = "100%";
        } else if (screen.width < 1500) {
          this.size = "90%";
        } else if (screen.width < 1700) {
          this.size = "85%";
        } else {
          this.size = "80%";
        }
      });
    },
    checkCart() {
      this.emptyCart = this.items.length == 0 ? true : false;
    },
    addUpsale(varid) {
      this.addItem({id: varid, quantity: 1});
    },
    addGWPtoCart(varid) {
      if (this.cart.promocode && this.cart.promocode_) {
        this.addItem({
          items: [{id: varid, quantity: 1}],
          promocode: this.cart.promocode,
        });
      } else {
        this.addItem({id: varid, quantity: 1});
      }
    },

    onClosing() {
      /**
       * Check if the cart is still visible by our `cart.js` store
       * if so, run `hideCart` so it updates the local `visible` store value
       */
      if (this.visible) {
        this.hideCart();
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        (charCode > 31 && (charCode < 48 || charCode > 57)) ||
        charCode == 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    handleInputChange(value, item) {
      var val = parseInt(value);
      var product = item;

      if (value === "0") {
        val = 1;
      }

      this.updItem({item: product, type: "update", qty: val});
    },
    showFreeShippingBlock() {
      this.freeShipp = true;
    },
    noteMessages(newMess) {
      if (this.$refs.myTextarea !== document.activeElement) {
        this.updNote(newMess);
      }
    },
    updNoteMess() {
      if (this.cartData.item_count === 0) {
        this.updNote("");
      }
    },
    // This function redirect user to the wholesale cart in case if it's a wholesale customer
    showCart() {
      if (document.querySelector("body").classList.contains("isWh")) {
        window.location.pathname = "/pages/ws-cart";
      } else {
        this.toggle();
        this.updNoteMess();
      }
    },
    // Filter emojis from textarea
    filterEmojis() {
      const regex =
        /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{2300}-\u{23FF}\u{2B50}\u{2B06}\u{2934}\u{2935}\u{25AA}\u{25AB}\u{25FE}\u{25FD}\u{25FB}\u{25FC}\u{25B6}\u{25C0}\u{1F004}\u{1F0CF}]/gu;

      this.noteMessage = this.noteMessage.replace(regex, "");
    },
    decodeHTML(html) {
      let textarea = document.createElement("textarea");
      textarea.innerHTML = html;
      return textarea.value;
    },
    totalWithoutSubsc(arr) {
      return arr.reduce((sum, obj) => {
        // Check if the current object does not have the property selling_plan_allocation
        if (
          !Object.prototype.hasOwnProperty.call(obj, "selling_plan_allocation")
        ) {
          // Multiply price with quantity and add to sum
          return sum + obj.price * obj.quantity;
        }
        return sum; // If the property exists, return current sum without modification
      }, 0);
    },
  },
  watch: {
    emptyCart(state) {
      if (state) {
        if (screen.width < 768) {
          this.size = "100%";
        } else {
          this.size = "390px";
        }
      } else {
        if (screen.width < 1420) {
          this.size = "100%";
        } else if (screen.width < 1500) {
          this.size = "90%";
        } else if (screen.width < 1700) {
          this.size = "85%";
        } else {
          this.size = "80%";
        }
      }
    },
    eligibleGWP(status) {
      if (status == false) {
        this.gwpExp = false;
      } else {
        this.gwpExp = true;
      }
    },
    cartData(newVal) {
      this.totalWS = this.totalWithoutSubsc(newVal.items);
      this.totalSavings();
      this.noteMessage = this.cartData.note;
      // check if gwp product is in the cart to disable or enable button
      var gwpfromcart = newVal.items.find(
        obj => obj.id === this.cart.gwp_product,
      );
      if (gwpfromcart) {
        this.cartHasGWP = true;
        // also check if user try to skip the minimal amount then remove gift from cart
        if (
          parseFloat(
            formatMoney(this.cartData.items_subtotal_price, "{{amount}}"),
          ) -
            parseFloat(formatMoney(gwpfromcart.price, "{{amount}}")) <
          parseFloat(this.cart.threshold)
        ) {
          this.remItem(gwpfromcart);
        }
      } else {
        this.cartHasGWP = false;
      }
      var gwpfromcart1 = newVal.items.find(
        obj => obj.id === this.cart.gwp_product_1,
      );
      if (gwpfromcart1) {
        this.cartHasGWP1 = true;
        // also check if user try to skip the minimal amount then remove gift from cart
        if (
          parseFloat(
            formatMoney(this.cartData.items_subtotal_price, "{{amount}}"),
          ) -
            parseFloat(formatMoney(gwpfromcart1.price, "{{amount}}")) <
          parseFloat(this.cart.threshold)
        ) {
          this.remItem(gwpfromcart1);
        }
      } else {
        this.cartHasGWP1 = false;
      }
      var gwpfromcart2 = newVal.items.find(
        obj => obj.id === this.cart.gwp_product_2,
      );
      if (gwpfromcart2) {
        this.cartHasGWP2 = true;
        // also check if user try to skip the minimal amount then remove gift from cart
        if (
          parseFloat(
            formatMoney(this.cartData.items_subtotal_price, "{{amount}}"),
          ) -
            parseFloat(formatMoney(gwpfromcart2.price, "{{amount}}")) <
          parseFloat(this.cart.threshold)
        ) {
          this.remItem(gwpfromcart2);
        }
      } else {
        this.cartHasGWP2 = false;
      }
    },
  },
  mounted() {
    console.log(this.cart);
    this.cartSize();
    setTimeout(() => {
      //   console.log(this.cartData);
      this.totalSavings;
    }, 2000);

    if (window.__theme) {
      window.__theme.addToCart = this.addItem;
    }
    this.updNoteMess();
  },
};
</script>
