<template>
  <a
    class="lg:my-2 flex items-center max-sm:justify-between lg:justify-start"
    :class="[`text-${color}`, {'cursor-default': link === 'false'}]"
    href="#reviews"
    @click.prevent="scrollToReviews"
  >
    <div class="mr-2">
      <div class="ratings">
        <div class="empty-stars"></div>
        <div class="full-stars" :style="this.customRound(this.average)"></div>
      </div>
    </div>
    <p
      class="leading-none"
      :class="[
        {
          // underline: productId && link !== 'false',
          // 'text-xs': color === 'caribe',
          // 'text-sm font-medium': color === 'sazon-500',
        },
        linkColor,
      ]"
    >
      <span
        class="font-brother text-sm font-semibold capitalize tracking-[0.75px]"
      >
        <span v-if="count >= 1" class="underline"
          >{{ count }}&nbsp;Reviews</span
        >
        <!-- <span v-else-if="count === 0" class="underline max-md:hidden"
          >{{ count }}&nbsp;Reviews</span
        >
        <span class="underline max-md:hidden" v-else
          >{{ count }} &nbsp;Review</span
        > -->
        <!-- <span class="flex md:hidden">{{ count }}</span> -->
      </span>
    </p>
  </a>
</template>
<script>
import axios from "axios";
import scrollTo from "../../mixins/scrollto";
export default {
  name: "Junip",
  mixins: [scrollTo],
  props: {
    color: {
      type: String,
      default: "caribe",
    },
    productId: {
      type: Number,
    },
    storeKey: {
      type: String,
      required: true,
    },
    countPlaceholder: {
      type: String,
      required: false,
    },
    link: {
      type: String,
    },
    linkColor: {
      type: String,
      default: "text-black-900",
    },
  },
  data: () => {
    return {
      key: "CP9UUhju5nUNfaMofet11Bwo",
      count: 0,
      average: 0,
      loading: true,
      errors: false,
      reviews: [],
    };
  },
  computed: {},
  mounted() {
    let url = `https://api.juniphq.com/v1/product_overviews`;
    if (this.productId) {
      url = `https://api.juniphq.com/v1/products?filter[remote_ids]=${this.productId}`;
    }
    axios
      .get(url, {
        headers: {
          "Junip-Store-Key": this.storeKey,
        },
      })
      .then(({status, data}) => {
        if (status === 200) {
          if (this.productId) {
            const product = data.products[0];
            const {rating_count, rating_average} = product;
            this.count = rating_count;
            this.average = rating_average;
          } else {
            this.average = data.product_overviews[0].rating_average;
            this.count = data.product_overviews[0].rating_count;
          }
        } else {
          this.errors = true;
        }
        this.loading = false;
      })
      .catch(err => {
        this.errors = true;
        this.loading = false;
      });
  },
  methods: {
    scrollToReviews() {
      if (this.link == "false") {
        return;
      }
      if (this.link) {
        window.location.href = this.link;
        return;
      }
      const height = {
        mobile: 171,
        desktop: 141,
      };
      const div = document.getElementById("reviews");
      if (div) {
        this.scrollTo(div, height);
      }
    },
    customRound(number) {
      let style;
      if (number > 0) {
        style = "width:" + (number * 100) / 5 + "%";
      } else {
        style = "width:0%;";
      }
      return style;
    },
  },
};
</script>
<style>
.ratings {
  position: relative;
  vertical-align: middle;
  display: inline-block;
  color: #9faea7;
  overflow: hidden;
}
.full-stars {
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
  overflow: hidden;
  color: #80bd42;
}
.empty-stars {
  margin-right: 16px;
}
.empty-stars:before,
.full-stars:before {
  content: "\2605 \2605 \2605 \2605 \2605";
  font-size: 20px;
}
.empty-stars:before {
  -webkit-text-stroke: 0px #9faea7;
}
.full-stars:before {
  -webkit-text-stroke: 0px #80bd42;
}
/* Webkit-text-stroke is not supported on firefox or IE */

/* Firefox */
@-moz-document url-prefix() {
  .full-stars {
    color: #80bd42;
  }
}
</style>
