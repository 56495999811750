<template>
  <div
    :aria-hidden="!visibleSingleProduct"
    class="px-3 absolute bottom-0 top-0 z-50 h-[406px] w-full overflow-y-auto border border-[2px] border-black border-pastelPinkL bg-white p-20 max-lg:right-0 max-sm:h-[380px] md:w-[421px] lg:right-[118px]"
    :class="{
      'top-[-500px]': !visibleSingleProduct,
      'top-[100%]': visibleSingleProduct,
    }"
  >
    <div class="relative mb-20 flex items-center justify-between">
      <div id="sr-cart-alert" class="sr-only" aria-live="polite"></div>
      <span class="mt-5 flex items-center gap-10">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="13"
          viewBox="0 0 16 13"
          fill="none"
        >
          <path
            d="M1 6.88235L5.08333 11L15 1"
            stroke="#3F3F3F"
            stroke-width="2"
          />
        </svg>
        Item added to your cart
      </span>
      <button
        class="absolute right-5 top-5"
        @click="close"
        :disabled="!visibleSingleProduct"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
        >
          <line
            x1="0.707107"
            y1="1.29289"
            x2="19.7071"
            y2="20.2929"
            stroke="#8E297A"
            stroke-width="2"
          />
          <path
            d="M20.2928 1.29285L1.29285 20.2928"
            stroke="#8E297A"
            stroke-width="2"
          />
        </svg>
      </button>
      <p class="sr-only">Cart</p>
      <p class="sr-only">{{ cartData.item_count }}</p>
    </div>
    <div>
      <cart-item-single
        v-if="firstItem"
        :key="firstItem.key"
        :item="firstItem"
      />

      <div
        v-if="items.length == 0 && !loading"
        class="tracking-2p py-8 text-center uppercase"
      >
        No items in cart
      </div>
    </div>
    <div v-if="items.length !== 0" class="mt-10 flex flex-col">
      <!-- <p class="flex justify-between">
        <span>Subtotl: </span>
        <span>{{ formatMoney(cartData.items_subtotal_price) }}</span>
      </p>

      <p class="flex justify-between">
        <span>total: </span>
        <span>{{ formatMoney(cartData.total_price) }}</span>
      </p> -->
      <a
        class="btn-block mb-10 !w-full !max-w-full"
        onclick="document.querySelector('button.absolute').click(); document.querySelector('a.cursor-pointer').click(); return false;"
        href="/cart"
        >View Cart ({{ cartData.item_count }})</a
      >
      <a
        class="btn-block mb-10 !w-full !max-w-full !border-pastelPinkL bg-pastelPinkL text-white"
        href="/checkout"
        :tabindex="!visibleSingleProduct ? -1 : 0"
        >Checkout</a
      >
      <button class="mx-auto border-b-[2px] p-5" @click="close">
        Continue Shopping
      </button>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {formatMoney} from "@shopify/theme-currency";
import CartItemSingle from "../CartItem/CartItemSingle.vue";

export default {
  components: {CartItemSingle},
  name: "StoreCartSingleProduct",
  props: {},
  computed: {
    ...mapState("cart", ["cartData", "visibleSingleProduct", "loading"]),

    items() {
      return this.cartData ? this.cartData.items : [];
    },
    firstItem() {
      return this.items.length ? this.items[0] : null;
    },
  },

  beforeMount() {
    // console.log("before mount");
    this.$store.dispatch("cart/initCart");
  },

  methods: {
    ...mapActions("cart", {
      init: "initCart",
      hide: "hideSingle",
    }),

    close() {
      this.hide();
    },

    formatMoney(value) {
      return formatMoney(value, "${{amount}}");
    },
  },
};
</script>
